.input-box {
   position: relative;
   .input-box-container {
      display: flex;
      height: 48px;
      border: 1px solid #ececec;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 10px;
      align-items: center;

      &.focus {
         border: 1px solid #f7b52c;
      }
   }
   .input {
      flex: 1;
      border: none;
      border: 0;
      border-radius: 10px;
      outline: none;
      background: transparent;
   }
   .arrow {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 6px solid #000;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid transparent;
      position: relative;
      top: 3px;
      transform-origin: 50% 25%;
      transition: all 0.1s linear;
      &.up {
         transform: rotate(180deg);
      }
   }
   .area-code {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid #ececec;
   }
   .select-area-box {
      position: absolute;
      top: 48px;
      left: 0;
      right: 0;
      height: 310px;
      box-sizing: border-box;
      padding-top: 4px;
      overflow: hidden;
      transition: height 0.2s ease-in-out;
      z-index: 10;
      &.hidden {
         height: 0;
      }
   }
   .send-code {
      color: #f7931a;
      cursor: pointer;
   }
}
.select-area {
   width: 100%;
   height: 100%;
   box-sizing: border-box;
   padding: 10px 0 0 0;
   display: flex;
   flex-flow: column;
   border: 1px solid #ececec;
   border-radius: 10px;
   background-color: #fff;
   .select-area-search-box {
      padding: 0 25px;

      .input-box-container {
         background-color: #f5f6f6;
         border: 1px solid #f5f6f6;
         height: 39px;
         &.focus {
            border: 1px solid #f5f6f6;
         }
      }
   }
   .select-area-lit {
      flex: 1;
      overflow: auto;
      margin-top: 10px;
      padding: 0 25px;
   }
   .area-title {
      color: rgba(2, 4, 13, 0.6);
      padding: 17px 0;
   }
   .area-item {
      display: flex;
      align-items: center;
      padding: 16px 0 19px 0;
      border-bottom: 1px solid #eee;
      .area-name {
         flex: 1;
      }
   }
}
