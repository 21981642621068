// 矿机引导下载
.download-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(13,11,16,.3);
  @include flex(center, center);
  .center {
    padding: 55px 45px;
    background: #fff;
    border-radius: 24px;
    box-sizing: border-box;
    width: 370px;
    .label {
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
    }
    .btn-wp {
      @include flex(center, space-between, row);
      font-size: 14px;
      margin-top: 30px;
      div {
        cursor: pointer;
        height: 36px;
        flex: 1;
        text-align: center;
        line-height: 36px;
        border: 1px solid #bbb;
        color: #bbb;
        padding: 0 30px;
        border-radius: 10px;
        &:last-child {
          margin-left: 12px;
          color: #000;
          background-color: #FDD100;
          border-color: #FDD100;
        }
      }
    }
  }
}
// 注册页
.finish-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(13,11,16,.65);
  @include flex(center, center);
  .center {
    width: 480px;
    height: 340px;
    border-radius: 10px;
    background: #fff;
    box-sizing: border-box;
    padding: 25px;
    @include flex(flex-start, flex-start, column);
  }
  .title {
    font-size: 18px;
    line-height: 1.5;
    color: rgb(51,51,51);
    @include font(bold);
  }
  .msg-wp {
    width: 100%;
    height: 150px;
    background: url('../assets/imgs/register/dialog_bg.png') no-repeat;
    background-size: 100%;
    margin-top: 20px;
    padding: 35px 24px;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 1.5;
    &.en {
      padding: 24px;
    }
    p {
      color: #fff;
      margin: 0;
      margin-bottom: 18px;
      @include font(bold);
      &.en {
        margin-bottom: 0;
        max-width: 70%;
      }
    }
    span {
      color: rgba(253, 209, 0, 1);
    }
    &.en p {
      margin-bottom: 0;
    }
  }
  // img {
  //   width: 320px;
  // }
  .btn {
    margin: 25px auto 0;
    min-width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: rgba(253, 209, 0, 1);
    font-size: 16px;
    line-height: 50px;
    color: #000;
    font-weight: 600;
    text-align: center;
    padding: 0 20px;
    cursor: pointer;
    .icon {
      margin-right: 6px;
    }
  }
}