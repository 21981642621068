.mealbox {
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    width: 280px;
    margin-left: 28px;
    font-size: 13px;
    overflow: hidden;
    padding-bottom: 34px;
    margin-bottom: 80px;
    .top {
        height: 130px;
        width: 100%;
        position: relative;
        .bg {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .btc {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 70px;
            width: 52px;
        }
        .day {
            color: #FFF;
            font-size: 26px;
            @include font(bold);
            font-weight: 700;
            line-height: 1.5;
            position: absolute;
            z-index: 9;
            margin: auto;
            top: 24px;
            left: 0;
            right: 0;
            text-align: center;
        }
    }
    .box-title {
        @include font(medium);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5;
        @include flex(center, center);
        margin-bottom: 18px
    }

    .cell {
        padding: 0 22px;
        @include flex(null, null, column);
        margin-bottom: 15px;

        .cell-item {
            flex: 1;
            @include flex(center, space-between, row);
            color: #111;
            margin-top: 8px;
            span {
                line-height: 1.5;
            }
            .sub-text {
                color: #111;
            }
            .price-wp {
                @include flex(flex-end);
                flex-wrap: wrap;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                .price {
                    @include font(medium);
                }
                .off {
                    font-size: 14px;
                    color: #999;
                    text-decoration: line-through;
                    margin-left: 15px;
                }
            }
        }
    }
    .tag {
        display: inline-block;
        background: linear-gradient(270deg, #FFE297 -2.61%, #FFF3D3 97.33%);
        border-radius: 8px;
        height: 24px;
        line-height: 24px;
        padding: 0 14px;
        color: #794608;
        font-weight: 500;
    }
    .mx-auto {
        margin: auto;
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
    }
    .new {
        background: linear-gradient(270deg, #4F4D5C -2.61%, #767483 97.33%);
        color: #fff;
    }
    .progress {
        margin: 0 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: #FEF8EA;
        height: 14px;
        border-radius: 7px;
        position: relative;

        .inner {
            height: 12px;
            border-radius: 6px;
            background: linear-gradient(270deg, #FFD300 0.07%, #FFEE9E 99.89%);
            @include flex(center, flex-end);

            .text {
                color: #16151D;
                font-size: 12px;
                margin-right: 12px;
            }
        }

        .text1 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 11px;
            color: #16151D;
        }
    }

    .bottom {
        margin: 0 20px;
        margin-top: -10px;
    }
    .tag-wp {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0 20px;
        margin-top: -5px;
        height: 16px;
        font-size: 10px;
        .item {
            border: 1px solid;
            height: 16px;
            line-height: 16px;
            padding: 0 5px;
            border-radius: 4px;
            margin-left: 5px;
            &.blue {
                color: #005DE9;
                border-color: #005DE9;
            }
            &.red {
                color: #FF0000;
                border-color: #FF0000;
            }
        }
    }
}