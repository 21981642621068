.help {
    .ant-collapse>.ant-collapse-item >.ant-collapse-header {
        padding: 32px;
        @include font(medium);
    }
    .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
        padding: 0 32px 32px;
        p {
            margin: 0;
            font-size: 14px;
            color: #666;
        }
    }
    .ant-collapse-borderless {
        font-size: 18px;
        background-color: #F4F3EC;
        font-weight: 500;
    }
    .more {
        @include flex(null, center);
        margin-top: 80px;
        .item {
            @include flex(center);
            padding: 8px 20px;
            border: 1px solid #1A1A18;
            border-radius: 10px;
            cursor: pointer;
            user-select: none;

            &:active {
                opacity: 0.7;
            }

            >img {
                width: 13px;
                height: 13px;
                transform: rotate(-90deg);
            }

            >span {
                font-weight: 600;
                margin-right: 6px;
            }
        }
    }
}