.details-content {
    position: relative;
    .ball {
        right: 0;
        width: 315px;
        position: absolute;
        top: 50px;
    }
    .luck {
        right: 320px;
        width: 175px;
        position: absolute;
        top: 50px;
        animation: bounce-down 2s linear infinite;
    }
    .header-img {
        width: 100%;
    }

    .details-title {
        font-size: 22px;
        font-weight: 700;
        margin: 40px auto;
        @include flex(center, center);

        >span {
            margin-left: 10px;
            margin-right: 10px;
        }

        >img {
            height: 4px;
            width: 80px;
        }
    }

    .section {
        padding-bottom: 30px;

        .box-cell {
            box-sizing: border-box;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 15px;
            padding: 40px 50px;
        }
        .section-break-event {
            img {
                width: 100%;
                margin-left: -15px;
            }
        }
        .mb25 {
            margin-bottom: 25px;
        }
        .mb40 {
            margin-bottom: 40px;
        }
        .alert {
            @include flex(null, null, column);

            .title {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
                margin-top: 10px;
                @include font(medium);
            }

            .text {
                font-size: 13px;
                margin-bottom: 8px;
                line-height: 20px;
            }
        }
        .item-title {
            font-size: 16px;
            font-weight: 700;
            @include font(bold);
        }
        .section-details {
            margin-bottom: 40px;

            .section-details-item {
                .item-side {
                    @include flex();
                    margin-top: 30px;

                    >div {
                        flex: 1;
                        @include flex(null, null, column);
                        color: #666;

                        span:last-child {
                            color: #000;
                            font-weight: 600;
                            margin-top: 15px;
                            @include font(medium);
                        }
                    }

                    .mleft {
                        margin-left: 100px;
                    }
                }
            }
        }
        .break-event-wp {
            .title {
                font-size: 16px;
                color: #6BAE9C;
                @include font(bold);
            }
            .chart {
                margin-top: 33px;
                position: relative;
                .vs-icon {
                    position: absolute;
                    top: 0;
                    left: -25px;
                    bottom: 0;
                    margin: auto;
                    width: 50px;
                }
                & > div {
                    width: 100%;
                    border-radius: 4px;
                    background: #FFF9EE;
                    color: #5A4B30;
                    line-height: 1.5;
                    @include flex(center, center);
                    .left {
                        padding: 23px 73px;
                        width: 50%;
                        flex-shrink: 0;
                        box-sizing: border-box;
                        .text {
                            font-size: 16px;
                            @include font(bold);
                            &:last-child {
                                font-size: 12px;
                                margin-top: 10px;
                                @include font();
                            }
                        }
                    }
                    .right {
                        @include flex(center,center);
                        img {
                            width: 320px;
                        }
                        .text {
                            margin-left: 24px;
                            font-size: 14px;
                            color: #FF8E04;
                        }
                    }
                }
                .bottom {
                    background: #F8F8F8;
                    margin-top: 4px;
                    color: #858B8B;
                    .right .text {
                        color:#858B8B;
                    }
                }
            }
            ul {
                padding: 0;
                @include flex(center);
                margin-top:12px;
            }
            li {
                color: #6BAE9C;
                font-size: 14px;
                list-style: none;
                &:not(:first-child) {
                    margin-left: 50px;
                }
                span {
                    color: #333;
                    margin-left: 12px;
                }
            }
        }
        .section-income {
            margin-bottom: 40px;

            .section-income-item {
                .caption {
                    font-size: 16px;
                    font-weight: 700;
                    @include font(bold);
                    >span:last-child {
                        margin-left: 15px;
                        color: #52AF99;
                        font-size: 20px;
                    }
                }

                .income-item-side {
                    margin-top: 20px;
                    @include flex(center);

                    .left {
                        .charts {
                            width: 398px;
                            height: 222px;
                        }

                        .bottom {
                            @include flex(center, center);

                            >div {
                                @include flex(center);
                                color: #666;
                                font-size: 13px;

                                >span:first-child {
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 5px;
                                    margin-right: 8px;
                                }
                            }

                            .day1 {
                                margin-right: 40px;

                                >span:first-child {
                                    background-color: #52AF99;
                                }
                            }

                            .day2 {
                                >span:first-child {
                                    background-color: #F7B52C;
                                }
                            }
                        }
                    }

                    .right {
                        flex: 1;
                        margin-left: 100px;
                        line-height: 20px;
                        @include font(medium);
                        >p:last-child {
                            color: #666;
                            margin-top: 20px;

                        }
                    }
                }
            }
        }

        .section-head {
            box-sizing: border-box;
            width: 100%;
            margin: 0 auto 40px;
            padding: 0 50px;
            position: relative;
            background: #fff;
            border-radius: 15px;
            @include flex(center, center, row);
            .left {
                img {
                    width: 266px;
                }
                margin-left: 80px;
            }
            .right {
                flex: 1;
                margin-left: 100px;
            }
            .tag {
                display: inline-block;
                background: linear-gradient(270deg, #FFE297 -2.61%, #FFF3D3 97.33%);
                border-radius: 8px;
                height: 24px;
                line-height: 24px;
                padding: 0 14px;
                color: #794608;
                font-weight: 500;
            }
            .new {
                background: linear-gradient(270deg, #4F4D5C -2.61%, #767483 97.33%);
                color: #fff;
            }
            .head-bottom {
                @include flex(center, null, column);
                margin-bottom: 40px;

                    .right-cell {
                        @include flex(center, space-between);
                        margin-bottom: 20px;
                        width: 100%;

                        >div {
                            @include flex(null, null, column);

                            >span:first-child {
                                color: #666;
                                font-size: 14px;
                                margin-bottom: 12px;
                            }

                            >span:last-child {
                                font-size: 14px;
                                color: #000;
                                @include font(medium);
                            }
                        }
                    }

                    .progress {
                        margin-top: 20px;
                        margin-bottom: 30px;
                        background-color: #FEF8EA;
                        height: 14px;
                        width: 100%;
                        border-radius: 7px;
                        position: relative;

                        .inner {
                            height: 12px;
                            border-radius: 6px;
                            background-color: #F7A62C;
                            @include flex(center, flex-end);

                            .text {
                                color: #fff;
                                font-size: 12px;
                                margin-right: 12px;
                            }
                        }

                        .text1 {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 11px;
                            color: #999;
                        }
                    }

                    .head-right-bottom {
                        @include flex(center, space-between);
                        width: 100%;
                        .btn {
                            width: 170px;
                            height: 42px;
                        }
                        .right {
                            background-color: #000;
                            color: #fff;
                            padding: 16px 50px;
                            border-radius: 6px;
                            cursor: pointer;
                            user-select: none;
                            position: relative;

                            &:active {
                                opacity: 0.7;
                            }
                            .reduce {
                                background-color: #F7B52C;
                                font-size: 12px;
                                position: absolute;
                                top: 0;
                                right: 0;
                                border-bottom-left-radius: 10px;
                                padding-left: 4px;
                                padding-right: 2px;
                            }
                        }

                        .left {
                            font-weight: 600;
                            @include font(bold);
                            margin: 0;
                            >span:nth-child(2) {
                                margin-left: 8px;
                                margin-right: 8px;
                                font-size: 22px;
                            }

                            .price {
                                text-decoration: line-through;
                            }
                        }
                    }
            }

            .head-title {
                @include flex(center, space-between, row);
                padding-top: 30px;
                padding-bottom: 30px;
                .msg {
                    @include flex(center);
                    font-size: 18px;
                    font-weight: 700;
                    @include font(medium);

                    >img {
                        height: 54px;
                    }

                    >span:nth-child(2) {
                        margin: 0 15px;
                    }
                }
                
            }

            .head-float {
                position: absolute;
                top: 0;
                right: 50px;
                width: 42px;
                height: 42px;
                color: #fff;
                font-size: 12px;
                @include flex(center, center);
                border-bottom-left-radius: 36px;
                border-bottom-right-radius: 36px;

                .name {
                    text-align: center;
                    line-height: 16px;
                }
            }
            .output {
                height: 48px;
                width: 48px;
            }
        }

        .section-static {
            margin-bottom: 40px;

            .section-static-item {
                .caption {
                    font-size: 16px;
                    font-weight: 700;
                    @include font(bold);
                    >span:last-child {
                        margin-left: 15px;
                        color: #52AF99;
                        font-size: 20px;
                    }
                }

                .static-item-side {
                    @include flex(null, space-between);

                    .right {
                        flex: 1;

                        >div {
                            @include flex(null, null, column);
                            margin-top: 25px;

                            >span:last-child {
                                margin-top: 10px;
                                line-height: 20px;
                            }
                        }
                    }

                    .left {
                        margin-top: 20px;

                        >div {
                            @include flex(center);
                            margin-top: 20px;
                            color: #999;

                            >span:first-child {
                                width: 8px;
                                height: 8px;
                                border-radius: 5px;
                                background-color: #F7B52C;
                                margin-right: 8px;
                            }
                        }
                    }

                    .mid {
                        @include flex(flex-end);
                        margin-right: 100px;

                        .mid-left {
                            >span:first-child {
                                color: #999;
                            }

                            >p {
                                font-weight: 600;
                                font-size: 18px;
                                @include font(bold);
                            }
                        }
                    }
                }
            }
        }
    }
    .speeded-wp {
        .content {
            width: 100%;
            background: #fafafa;
            .item {
                height: 60px;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #4E3A36;
                &:first-child {
                    border-bottom: 1px dotted #ABCAC2;
                }
                &:last-child {
                    background-color: #E6F7F3;
                    color: #000;
                    div:first-child {
                        color: #52AF99;
                    }
                }
                div {
                    flex: 1;
                    text-align: center;
                    &:first-child {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}