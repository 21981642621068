.mine {
    width: 1200px;
    margin: auto;
    border-radius: 20px;
    overflow: hidden;
    .item {
        height: 590px;
        background-color: #666;
        @include flex(null, null, row);
        position: relative;

    }
    .ant-carousel-vertical .slick-dots {
        margin-right: 20px;
    }
    .ant-carousel-vertical .slick-dots li.slick-active button,
    .ant-carousel-vertical .slick-dots li,
    .ant-carousel-vertical .slick-dots li button {
        height: 160px;
        width: 4px;
    }
    .ant-carousel .slick-dots li.slick-active button {
        background-color: #FDD100;
    }
    .bg {
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
    }
    .left {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 80%;
        padding-top: 78px;
        box-sizing: border-box;
        padding-left: 50px;
        height: 100%;
        background: linear-gradient(89deg, #050505 44.37%, rgba(5, 5, 5, 0.00) 91.61%);
        .path {
            color: #FDD100;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            margin-top: 40px;
            @include font(medium);
        }
        .des-wp {
            color: #fff;
            line-height: 1.5;
            margin-top: 40px;
            max-width: 60%
        }
        
        .list {
            @include flex(center, flex-start, row);
            margin-left: -90px;
            margin-top: 18px;
            line-height: 1.5;
            color: #fff;
            & > div {
                margin-left: 90px;
            }
            .icon {
                background: #FDD100;
                width: 15px;
                height: 2px;
                border-radius: 1px;
                margin-top: 16px;
            }
        }
    }
    .right {
        flex: 1;
    }
}