.home {
   height: 100%;
   @include flex(null, null, column);

   .main {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      

      .policy {
         background-color: #000;
         color: #fff;
         @include flex(null, flex-end);
         .line {
            margin-left: 10px;
            margin-right: 10px;
         }

         .terms {
            cursor: pointer;
            user-select: none;
         }
      }
   }
}
