.platform {
    margin-bottom: 100px;
    .start-mining {
        ul {
            @include flex(center, space-between, row);
            width: 1200px;
            margin: auto;
            border-radius: 20px;
            background: #fff;
            padding: 0;
            @include font(medium);
            li {
                list-style: none;
                padding: 52px;
                position: relative;
                flex: 1;
                flex-shrink: 0;
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    content: ' ';
                    height: 100%;
                    width: 1px;
                    background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 2.08%, #D9D9D9 47.4%, rgba(217, 217, 217, 0.00) 100%);
                }
                img {
                    width: 105px;
                }
                .des {
                    margin-top: 20px;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.5
                    
                }
            }
        }
    }
    .introduce {
        @include flex(null, space-between, row);
        .right {
            flex: 1;
            position: relative;
            .moon {
                position: absolute;
                bottom: 100px;
                left: -60px;
                width: 530px;
            }
            .luck {
                position: absolute;
                bottom: 350px;
                width: 350px;
                right: 0;
                animation: bounce-down 2s linear infinite;
            }
            .coin1 {
                width: 105px;
                position: absolute;
                left: -210px;
                top: 40%;
             }
             .coin2 {
                width: 68px;
                transform: rotate(240deg);
                position: absolute;
                left: 50px;
                bottom: 90px;
             }
            .ball {
                width: 58px;
                position: absolute;
                top: 180px;
                right: 0;
            }
            .hoe {
                position: absolute;
                bottom: 350px;
                animation: bounce-down 1.5s linear infinite;
                animation-delay: .5s;
                width: 210px;
                left: -120px;
            }
        }
        .sub-text {
            margin-top: 26px;
            font-size: 18px;
            color: #666;
            font-weight: 400;
            line-height: 1.5;
            width: 780px;
        }
        .steps-wp {
            height: 300px;
            margin-bottom: 70px;
            .ant-steps.ant-steps-vertical {
                height: 100%;
                @include font(medium);
            }
            .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
                color: rgba(0, 0, 0, 0.88);
            }
            .ant-steps .ant-steps-item-process .ant-steps-item-icon,
            .ant-steps .ant-steps-item-icon {
                background: linear-gradient(169deg, #1A1A18 40.01%, #949494 93.84%);
                border-color: transparent;
                border: none;
                font-size: 18px;
                width: 28px;
                height: 28px;
                line-height: 28px;
                margin-left: 3px;
            }
            .ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon,
            .ant-steps-icon {
                color: #FDD100 !important;
                font-size: 16px !important;
                font-weight: 500;
            }
            .ant-steps-item-title {
                font-size: 16px;
                font-weight: normal;
            }
        }
        .ant-btn-primary {
            .icon {
                margin-right: 8px;
            }
            margin-bottom: 198px;
        }
    }
}