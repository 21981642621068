.ant-layout {
   height: 100%;
}

.layout-header {
   display: flex;
   justify-content: space-between;
   align-items: center;

   .layout-header-left {
      display: flex;
      align-items: center;

      .logo {
         width: 120px;
         height: 31px;
         background: rgba(255, 255, 255, 0.3);
      }

      .draw-icon {
         margin-left: 40px;
         font-size: 20px;
         color: #fff;
      }
   }
}

.layout-sider {
   .layout-sider-item {
      height: 100%;
      border-right: 0;
   }
}

.layout-content {
   padding: 0 16px 16px;
   overflow: auto;

   .breadcrumb {
      margin: 14px 0;
   }
}

.layout-footer {
   text-align: center;
   background: #F0F0F0;
}

.not-found {
   height: 100%;
   background: #ececec;
   text-align: center;

   img {
      margin-top: 20%;
   }
}
.ml6 {
   margin-left: 6px;
}