.register {
   height: 100%;
   .top {
      padding-top: 20px;
      padding-left: 30px;

      .img {
         height: 34px;
         cursor: pointer;
      }
   }
   .input-view {
      height: 48px;
      width: 100%;
      border: 1px solid #ececec;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 10px;
      &:focus {
         border-color: #f7b52b;
         outline: none; //这个可以把原来的线去掉
      }
   }
   .content {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      border-radius: 20px;
      overflow: hidden;

      .left {
         width: 500px;
         flex: 1;
         flex-shrink: 0;
         position: relative;
         background-color: rgba(255,255,255,.5);
         padding: 40px 0 40px;
         display: flex;
         flex-direction: column;
         align-items: center;
         box-sizing: border-box;overflow-wrap: anywhere;
         .text {
            text-align: center;
            line-height: 1.5;
            max-width: 80%;
         }
         .title {
            font-size: 24px;
            color: #000;
            font-weight: 600;
         }
         .sub-title {
            margin-top: 10px;
            font-size: 18px;
            font-weight: 400;
            max-width: 60%;
         }
         img {
            width: 260px;
            margin: 50px auto;
         }
         .banner {
            width: 382px;
            img {
               width: 100%;
               border-radius: 10px;
               margin: 0;
            }
         }
      }

      .right {
         width: 500px;
         background-color: #fff;
         box-sizing: border-box;
         padding: 40px;
         .topImg {
            width: 30px;
         }
         .regist-header {
            display: flex;
            align-items: center;
            padding-bottom: 40px;
         }

         .title {
            font-weight: bold;
            font-size: 17px;
            margin-left: 20px;
         }

         .form {
            margin-top: 15px;

            .btn {
               background-color: #f7b52c;
               width: 100%;
               font-weight: 600;
               height: 36px;
               &:active {
                  opacity: 0.6;
               }
            }
         }
      }
   }
   .regist-tab {
      .ant-tabs-nav-list {
         display: flex;
         width: 100%;
         .ant-tabs-tab {
            flex: 1;
            display: flex;
            justify-content: center;
            margin: 0;
         }
         .ant-tabs-ink-bar {
            background-color: transparent;
            // width: 100% !important;
         }
         .ant-tabs-tab:hover {
            .ant-tabs-tab-btn {
               color: #f7b52c;
            }
         }
         .ant-tabs-tab-active {
            position: relative;
            &::after {
               content: '';
               position: absolute;
               left: 0;
               bottom: 0;
               right: 0;
               height: 2px;
               background: #f7b52b;
            }
            .ant-tabs-tab-btn {
               color: #f7b52c;
            }
         }
      }
   }
   .agreen {
      color: rgba(2, 4, 13, 0.4);
      font-size: 12px;
      span:nth-last-child(1) {
         color: #f7b52c;
         cursor: pointer;
      }
   }
   
   .gologin {
      color: rgba(2, 4, 13, 0.4);
      font-size: 12px;
      text-align: right;
      margin-top: 20px;
      span:nth-last-child(1) {
         color: #f7b52c;
         cursor: pointer;
      }
   }
   .btn-box {
      text-align: center;
      margin-top: 24px;
      .btn {
         height: 39px;
         background: #f7b52b;
         width: 100%;
         color: #fff;
         border: none;
      }
      .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
         background: #f7b52b;
         color: #fff;
         opacity: .7;
      }
   }
   .reward {
      margin: 24px 0;
      position: relative;
      border-radius: 15px;
      padding: 15px 20px;
      background: linear-gradient(92.86deg, #FF6B47 24.82%, #FE8B42 75.71%);
      @include flex(null, center, column);
      
      .label {
         font-size: 20px;
         background: linear-gradient(180deg, #FFFFFF 0%, #FEEE78 100%);
         background-clip: text;
         margin: 0;
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
      }
      p{
         font-size: 14px;
         margin: 0;
         color: #FEFBD2;
         line-height: 1.5;
      }
      img {
         height: 16px;
         width: 100px;
         margin-bottom: 10px;
      }
      &.zh {
         font-family: register_label;
         p {
            font-family: -apple-system
         }
      }
      &.en {
         @include font();
         .label {
            font-size: 18px;
            @include font(bold)
         }
      }
      .welfare {
         position: absolute;
         right: 10px;
         top: 20px;
         bottom: 20px;
         margin: auto;
         width: 70px;
         height: 60px;
      }
   }
}




