.product {
    @include flex(null, null, column);
    .box {
        & > div {
            @include flex(null, null, column);
            
        }
        .list {
            @include flex(center, null, row);
            margin-left: -28px;
        }
    }
    .product-main {
        .list {
            @include flex(null, flex-start, row);
            margin-left: -23px;
            margin-bottom: 190px;
        }
        
        .mine-box {
            width: 280px;
            box-sizing: border-box;
            padding: 0;
            cursor: pointer;
            background: #fff;
            border-radius: 20px;
            position: relative;
            flex-shrink: 0;
            &:not(:first-child) {
                margin-left: 28px;
            }

            &:first-child {
                margin-left: 23px;
            }
            .details-wp {
              padding: 30px 17px 40px;
              .name {
                font-size: 18px;
                color: #191A1F;
                @include font(medium);
              }
              .tag-wp {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                .tag {
                  margin-left: 5px;
                  border-radius: 2px;
                  padding: 0 5px;
                  height: 18px;
                  line-height: 18px;
                  font-size: 12px;
                  border: 1px solid currentColor;
                  @include font(null);
                  &.red {
                    color: red;
                    border-color: red;
                  }
                }
              }
              .coin {
                font-size: 22px;
                color: #FFAC00;
                @include font(bold);
                margin-top: 15px;
              }
              .des-wp {
                @include flex(center, space-between, row);
                margin-top: 30px;
              }
              .des-label {
                font-size: 14px;
                color: #666;
                @include flex(center, space-between, row);
                width: 100%;
              }
            }
            .img-wp {
              height: 250px;
              width: 100%;
              border-radius:  20px 20px 0 0;
              background: #f8f8f8;
              @include flex(center, center);
              img {
                margin-top: 50px;
                width: 145px;
              }
            }
            .svg-label {
              position: absolute;
              top: 30px;
              left: -6px;
              box-sizing: border-box;
              line-height: 1.5;
              border-radius: 5px 5px 5px 0;
              background: currentcolor;
              height: 28px;
              line-height: 28px;
              min-width: 78px;
              p {
                color: #fff;
                font-size: 14px;
                margin: 0;
                padding: 0 8px ;
                text-align: center;
              }
              .after {
                position: absolute;
                left: 0;
                bottom: -6px;
                border-width: 3px;
                border-style: solid;
                border-color: currentcolor currentcolor transparent transparent;
              }
              .after-maker {
                position: absolute;
                left: 0;
                bottom: -6px;
                border-width: 3px;
                border-style: solid;
                z-index: 9;
                border-color: rgba(0,0,0,.5) rgba(0,0,0,.5) transparent transparent;
              }
            }
          }
        .procure-mine-box {
          .img-wp img{
            margin-top: 0;
          }
          .buttom {
            border-top: 1px solid #eee;
            padding: 24px 0;
          }
          .details-wp {
            padding-bottom: 0;
            p {
              @include flex(center, flex-start, row);
            }
            span {
              font-size: 12px;
              color: #666;
              &.val {
                font-size: 14px;
                color: #191A1F;
              } 
            }
          }
          
        }
        .section {
            width: 280px;
            margin-left: 23px;
            border-radius: 20px;
            background: #fff;
            padding-bottom: 60px; 
            position: relative;
            @include flex(center, space-between, column);
            @include font();
            .icon {
                background: linear-gradient(90deg, rgba(255,237,200,0.02) 0%, rgba(255,234,191,1) 51%, rgba(255,229,174,0) 100%);
                position: absolute;
                left: -10px;
                top: 28px;
                width: 165px;
                height: 34px;
                line-height: 34px;
                padding-left: 30px;
                color: #794608;
            }
            .section-top {
                margin-top: 95px;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 1.5;
                text-align: center;
                @include font(medium);
            }
            
            img {
                width: 260px;
                height: 260px;
            }
            .parameter {
                margin-top: 30px;
                font-size: 18px;
                font-weight: 500;
            }
            .price {
                margin-top: 26px;
                font-size: 14px;
                font-weight: 500;
                span {
                    color: #FFAC00;
                    @include font(medium);
                    font-size: 24px;
                    font-weight: 600;
                    margin-left: 10px;
                }
            }
        }
    }
}