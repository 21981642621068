/* flex布局 */
@mixin flex($a: null, $j: null, $d: null) {
    display: flex;

    @if $d {
        flex-direction: $d;
    }

    @if $j {
        justify-content: $j;
    }

    @if $a {
        align-items: $a;
    }
}
@mixin font($a: null) {
    @if $a == bold {
        font-family: 'TT Normas Pro Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
        font-weight: 700;
    }
    @else if  $a == medium {
        font-family: 'TT Normas Pro Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
        font-weight: bold;
    }
    @else {
        font-family: 'TT Normas Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
        font-weight: normal;
    }
}
@import './layout.scss';
@import 'register.scss';
@import 'home.scss';
@import 'platform.scss';
@import 'product.scss';
@import 'mine.scss';
@import 'download.scss';
@import 'help.scss';
@import 'contact.scss';
@import 'mealbox.scss';
@import 'details.scss';
@import './header.scss';
@import './notfound.scss';
@import './allModal';
@import './footer';
@import './player';
html {
    height: 100%;
}

@font-face {
    font-family: 'TT Normas Pro';
    src: url(../assets/font/TT_Norms_Pro_Regular.otf);
}
@font-face {
    font-family: 'TT Normas Pro Bold';
    src: url(../assets/font/TT_Norms_Pro_Bold.otf);
}
@font-face {
    font-family: 'TT Normas Pro Medium';
    src: url(../assets/font/TT_Norms_Pro_Medium.otf);
}
@font-face {
    font-family: 'register_label';
    src: url(../assets/font/register.ttf);
}
body {
    height: 100%;
    margin: 0;
    @include font();
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
h1 {
    font-size: 60px;
    line-height: 1.5;
    margin: 0;
    @include font(bold);
}
h2 {
    text-align: left;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 55px;
    @include font(bold);
}
h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    @include font(medium);
}
.view {
    width: 1200px;
    margin: auto;
    padding-top: 110px;
}
.ant-btn {
    @include font(medium);
}
#root {
    height: 100%;
    font-size: 14px;
    color: #1A1A18;
    background: #F4F3EC;
}

.ant-btn:not(.ant-btn-primary) {
    border: 1px solid #999;
    &:hover {
        border-color: #FDD100;
        color: #FDD100;
    }
}
.ant-btn-primary {
    background: #FDD100;
    color: #1A1A18;
    font-size: 20px;
    font-weight: 500;
    height: 56px;
    border: none;
    &:not(:disabled):not(.ant-btn-disabled):hover {
        background: #E5BE00;
        color: #1A1A18;
        border: none;
    }
}
// .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
// .ant-btn:not(:disabled):not(.ant-btn-disabled):hover{
//     background: #E5BE00;
//     color: #1A1A18;
//     border: none;
// }
@keyframes bounce-down {
    25% {
         -webkit-transform: translateY(-4px);
    }
    50%, 100% {
         -webkit-transform: translateY(0);
    }
    75% {
         -webkit-transform: translateY(4px);
    }
 }