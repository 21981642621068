.contact {
    margin-top: 100px;
    padding-bottom: 200px;
    position: relative;
    .bg {
        position: absolute;
        bottom: 0px;
        left: 0;
    }
    a {
        color: #fff;
        text-decoration: none;
        outline: none;
        &:link {
            text-decoration: none;
            outline: none;
        }
        &:hover{
            color:#fff;
            opacity: .8;
        }
    }
    .content {
        width: 1200px;
        margin: auto;
        position: relative;
        z-index: 9;
        border-radius: 20px;
        height: 485px;
        background: linear-gradient(180deg, #464646 -18.04%, #100C0D 108.87%);
        color: #fff;
        padding-top: 50px;
        h2 {
            text-align: center;
            margin-bottom: 0;
        }
        .luck {
            position: absolute;
            width: 299px;
            height: 534px;
            right: 10px;
            top: -100px;
        }
    }
    .link {
        @include flex(center, null, row);
        box-sizing: border-box;
        padding: 0 90px;
        position: relative;
        .item {
            flex: 1;
            margin-top: 80px;
            padding-left: 50px;
            img {
                height: 50px;
            }
            .top {
                @include flex(center, null, row);
                margin-bottom: 30px;
                span {
                    margin-left: 20px;
                    font-size: 26px;
                    font-weight: 500;
                    @include font(medium);
                }
            }
            .cell {
                margin-top: 10px;
                span {
                    color: rgba(255,255,255, .6);
                    
                }
            } 
        }
    }
    
}