.not-found-view {
  height: 100%;
   @include flex(null, null, column);
   .main {
    flex: 1;
    @include flex(center, center, column);
    padding-top: 120px;
    img {
      width: 500px;
      margin-bottom: 20px;
     }
     .mb80 {
      margin-bottom: 80px;
     }
   }
   
}