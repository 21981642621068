.header {
  .header-content {
     @include flex(center, space-between);
     padding: 0 70px;
     height: 80px;

     > img {
        height: 30px;
     }
     .header-center {
        flex: 1;
        display: flex;
        justify-content: center;
     }
     .tabs {
        margin-top: 11px;

        .ant-tabs-ink-bar {
           background-color: transparent;
        }
        .ant-tabs-tab {
           font-size: 16px;
           font-weight: 400;
        }
        .ant-tabs-tab:hover {
           color: #BE8607;
        }
        .ant-tabs-nav::before {
           border: none;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
           color: #BE8607;
        }

        .ant-tabs-tab + .ant-tabs-tab {
           margin: 0 0 0 40px;
        }
     }

     .tabs-reset {
        .ant-tabs-tab + .ant-tabs-tab {
           margin: 0 0 0 25px;
        }
     }

     .header-right {
        @include flex(center);

        .regist {
           color: #1A1A18;
           padding: 8px 14px;
           border-radius: 8px;
           background: #FDD100;
           cursor: pointer;
           margin-right: 30px;

           &:hover {
              opacity: 0.7;
           }
        }

        .lang {
           color: #1A1A18;
           cursor: pointer;
           user-select: none;
           text-align: center;
           @include flex(center);
           &:active {
              opacity: 0.6;
           }

           > .icon {
              font-size: 20px;
              margin-right: 10px;
           }
        }
     }
  }

  .header-reset {
     justify-content: flex-start;
     gap: 45px;
  }
}