.download {
    margin-bottom: 100px;
    @include flex(null, center);
    a {
        color: #BE8607;
        @include font(medium);
        text-decoration: none;
        outline: none;
        &:link {
            text-decoration: none;
            outline: none;
        }
        &:hover{
            color:#BE8607;
            opacity: .8;
        }
    }
    .left {
        img {
            width: 480px;
        }
        
    }
    .right {
        @include flex(center, null, column);
        flex: 1;
        margin-left: 110px;
        .qr-code {
            @include flex(center, space-between, row);
            width: 100%;
            .code {
                img {
                    width: 140px;
                }
                p {
                    color: #666;
                    margin: 0;
                    text-align: center;
                }
            }
            
        }
        .download {
            @include flex(flex-start, null, column);
            width: 100%;
            .label {
                font-size: 16px;
                line-height: 1.5;
                @include font(medium);
            }
            p {
                margin-top: 20px;
                line-height: 1.5;
            }
            .model {
                height: 57px;
                margin-top: 17px;
                img {
                    height: 57px;
                }
            }
        }
    }
    
}